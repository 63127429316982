import { appConfig } from "../../config/appConfig";
// import { logOut } from "../../context/UserContext";
import { UserConfig } from "../../config/userConfig";


export class ApiServiceError extends Error{
    json: any;
    constructor(message: string | undefined,public error?: Error, public response?: Response){
        super(message);
    }
}

export class ServiceDescriptor {
    // path: string
    baseUrl = appConfig.baseUrl;
    getUrl = () => this.baseUrl + this.path;

    constructor(public readonly path: string,
        public readonly method: "POST" | "GET" | "PUT" | "DELETE",
        public tokenProvider?: () => string | null,
        public tokenUpdater?: (token: string) => any) {
    }
}

export const genericRequest: <TInput, TOutput>(desc: ServiceDescriptor,
    inputData: TInput, signal?: AbortSignal) => Promise<TOutput> =
    <TInput, TOutput>(desc: ServiceDescriptor,
        inputData: TInput, signal?: AbortSignal) => {
        
        const url = desc.getUrl();
        const method = desc.method;
        const headers = new Headers();
        const tokenUpdater = desc.tokenUpdater;
        const tokenProvider = desc.tokenProvider;
        const token = tokenProvider && tokenProvider();
        const userAgentAsPatient = UserConfig.getPatientLoggedByAgent();

        // headers.append("Content-Type", "application/json; charset=utf-8");
        if (token) {
            headers.append("authorization", "Bearer " + token);
        }
        if(userAgentAsPatient !== null){
            headers.append("agentAsPatient", JSON.stringify(userAgentAsPatient));
        }
        
        let body: string | FormData | undefined = inputData && JSON.stringify(inputData);
        if (inputData instanceof FormData) {
            body = inputData;
        } else {
            headers.append("Content-Type", "application/json; charset=utf-8");
        }
        
        return new Promise((resolve, reject) => {
            fetch(url, { headers, body, method, signal }).then(
                async (response) => {

                    const newToken = response.headers.get("newToken");
                    newToken && tokenUpdater && tokenUpdater(newToken);

                    if (response.status < 400) {
                        // TODO Kero: do not parse if the OutputType is unknowen or undefined or null
                        let json = undefined
                        try {
                            json = await response.json()
                        } catch (error) {
                            console.error(error)
                        }
                            
                        resolve(json);
                        return;
                    // } else if (response.status === 401) {
                    //     logOut()
                    } else {
                       let json = await response.json()
                       let error = new ApiServiceError(` server error with status code ${response.status} and message: ${JSON.stringify(json.errors)} ` , undefined, response)
                        error.json = json
                        reject(error)
                    }
                }).catch((error) => {
                    if (signal && error.code === 20 && error.name === 'AbortError') {
                        return;
                    }
                    reject(new ApiServiceError(error.message, error))
                });
        });
    }

export const genericPollingRequest: <TInput, TOutput>(
   desc: ServiceDescriptor,
   inputData: TInput,
   pollingInterval: number,
   maxPollingDuration: number,
   pollingEnds: (responseBody: TOutput) => boolean,
   signal?: AbortSignal
) => Promise<TOutput> = async <TInput, TOutput>(
   desc: ServiceDescriptor,
   inputData: TInput,
   pollingInterval: number,
   maxPollingDuration: number,
   pollingEnds: (responseBody: TOutput) => boolean,
   signal?: AbortSignal
) =>
   new Promise((resolve, reject) => {
      const url = desc.getUrl();
      const method = desc.method;
      const headers = new Headers();
      const tokenUpdater = desc.tokenUpdater;
      const tokenProvider = desc.tokenProvider;
      const token = tokenProvider && tokenProvider();
      const userAgentAsPatient = UserConfig.getPatientLoggedByAgent();

      if (token) headers.append('authorization', 'Bearer ' + token);

      if (userAgentAsPatient !== null) {
         headers.append('agentAsPatient', JSON.stringify(userAgentAsPatient));
      }

      let body: string | FormData | undefined =
         inputData && JSON.stringify(inputData);

      if (inputData instanceof FormData) {
         body = inputData;
      } else {
         headers.append('Content-Type', 'application/json; charset=utf-8');
      }

      const startTime = Date.now(); // Record the start time

      const makeRequest = async () => {
         try {
            const response = await fetch(url, {
               headers,
               body,
               method,
               signal,
            });
            const responseBody = await response.json();

            const newToken = response.headers.get('newToken');
            newToken && tokenUpdater && tokenUpdater(newToken);

            if (response.status >= 400) {
               let error = new ApiServiceError(
                  ` server error with status code ${response.status} and message: ${JSON.stringify(responseBody.json)} `,
                  undefined,
                  response
               );
               error.json = responseBody;
               reject(error);
               return;
            }

            if (pollingEnds(responseBody)) {
               resolve(responseBody);
               return;
            } // Stop polling if success response

            const elapsedTime = Date.now() - startTime;

            if (elapsedTime < maxPollingDuration)
               setTimeout(makeRequest, pollingInterval);
            // Schedule next request
            else {
               let error = new ApiServiceError(
                  ` maximum polling duration reached, status code ${response.status}, message: ${responseBody} `,
                  undefined,
                  response
               );
               error.json = responseBody;
               reject(error);
               return;
            }
         } catch (error: any) {
            if (signal && error.code === 20 && error.name === 'AbortError') {
               reject(error);
               return;
            }
            reject(error);
            return;
         }
      };

      makeRequest(); // Start the first request
   });