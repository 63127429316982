import React, { useState, useEffect } from "react";
import { IAppointmentInfo } from "../../book-appointment/AppointmentModels";
import { Link, navigate } from "@reach/router";
import { UncontrolledTooltip, Button } from "reactstrap";
import { UserConfig } from "../../../config/userConfig";
import { lockAppointment, unlockAppointment, reschedulePatientAppointment, cancelPatientAppointment, getPhysicianAppointmentDetails, IUnlockReason, getPhysicianInfo, getZoomMeetingLink, startAppointment, cancelPhysicianAppointment, LoadingState } from "../../../core/service/services";
import { RescheduleAppointmentModal } from "../patient/RescheduleAppointmentModal";
import { toastSuccess, toastError } from "../../../App";
import { UnlockAppointmentReasonModal } from "../patient/UnlockAppointmentReasonModal";
import { AlertMessage } from "../../../core/alert/Alerts";
import moment from "moment";
import { useAppDispatch } from "../../../redux/hooks";
import { addressUpdated } from "../../../redux/slices/general/patientInfoSlice";

interface IProps {
  appointment: IAppointmentInfo,
  hasLockedAppointment: boolean,
  refresh?: () => void,
  updateItem?: (item: IAppointmentInfo) => void,
  onChatOpened?: (appointmentId: string, receiverName: string) => void,
  removeItem?: (itemId: string) => void,
  timeBeforeLock?: number,
  fromAppointmentDetails: boolean
}

const validateEmail = (email?: string | null) => {
  if (email == null || email.length < 1) {
    return false;
  }
  let re = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
  return re.test(email);
}

export const AppointmentActions: React.FC<IProps> = (props) => {
  let url = "";
  const [rescheduleModal, setRescheduleModal] = useState<boolean>(false)
  const [unlockModal, setUnlockModal] = useState<boolean>(false)
  const [cancelModal, setCancelModal] = useState<boolean>(false)
  const [timeBeforeLock, setTimeBeforeLock] = useState<number>(15)
  const [isLocked, setIsLocked] = useState<LoadingState>(LoadingState.nuteral);

  const dispatch = useAppDispatch()

  useEffect(() => {
    props.timeBeforeLock && setTimeBeforeLock(props.timeBeforeLock)
  }, [props.timeBeforeLock])

  const toggleRescheduleModal = () => {
    setRescheduleModal(!rescheduleModal)
  }

  const toggleUnlockModal = () => {
    setUnlockModal(!unlockModal)
  }

  const toggleCancelModal = () => {
    setCancelModal(!cancelModal)
  }

  async function callPatient() {
    const app = await getPhysicianAppointmentDetails(props.appointment.appointmentId)
    copyToClipboard("*67" + app.contact.phone)
    navigate("tel:*67" + app.contact.phone)
  }
  const copyToClipboard = (number: string) => {
    navigator.clipboard.writeText(number).then(() => {
      toastSuccess("Copied Number To ClipBoard")
    })

  };

  const call = (e: React.MouseEvent) => {
    e.preventDefault()
    if (props.appointment.status === "Locked") {
      startAppointment(props.appointment.appointmentId).then(res => {
        if (res.status === "Started") {
          callPatient()
          if (props.fromAppointmentDetails) {
            props.refresh && props.refresh()
          }
        }
      }).catch(err => toastError(err.message))
    } else {
      callPatient()
    }
  }

  const lock = () => {
    setIsLocked(LoadingState.loading);
    toastSuccess("Locking Appointment in progess....")
    lockAppointment(props.appointment.appointmentId).then(res => {
      if ('message' in res) {
        toastError(res.message)
      } else {
        props.refresh && props.refresh()
        window.scrollTo(0, 0)
      }
      setIsLocked(LoadingState.succeed);
    }).catch((error) => {
      let errorObj = JSON.parse(JSON.stringify(error));
      toastError(errorObj.json.message);
      setIsLocked(LoadingState.failed);
    })
  }

  const unlock = (e: React.MouseEvent) => {
    e.preventDefault()
    setUnlockModal(true);
    toggleUnlockModal()
  }

  const reschedule = (e: React.MouseEvent) => {
    e.preventDefault()
    dispatch(addressUpdated({ stateName: props.appointment.patientState }))
    toggleRescheduleModal()
  }

  const cancelByPatient = (res: boolean) => {
    if (res) {
      cancelPatientAppointment(props.appointment.appointmentId).then(res => {
        toastSuccess(res.message)
        props.removeItem && props.removeItem(props.appointment.appointmentId)
      })
    }
    else {
      toggleCancelModal()
    }
  }

  const cancelByPhysician = () => {
    cancelPhysicianAppointment(props.appointment.appointmentId).then(res => {
      props.refresh && props.refresh()
    }).catch(err => toastError(err.message))
    // toggleUnlockModal()
  }

  const unlockBtn = () => (
    <Link to="" onClick={unlock} className="text-warning">
      <UncontrolledTooltip placement="top" target="unlockAppointment">Unlock Appointment</UncontrolledTooltip>
      <i className="fa fa-unlock-alt" id="unlockAppointment" />
    </Link>
  )

  async function startVideoCall() {
    const iPhInfo = await getPhysicianInfo();
    const zoomAccount = iPhInfo.contactInfo?.zoomAccount;
    if (zoomAccount && validateEmail(zoomAccount)) {
      getMeeting(zoomAccount).then(() => {
        window.open(url, "_blank")
      })
    } else {
      toastError("You have to provide a valid Zoom Account Email in your settings page.")
    }
  }

  const videoChatBtn = () => (
    <>
      {props.fromAppointmentDetails &&
        <div className="p-2 d-inline-block">
          Start Video Call:
        </div>
      }
      <Link to="" onClick={startVideoCall}>
        <UncontrolledTooltip placement="top" target="videoConference">Start Video Conference</UncontrolledTooltip>
        <i className="fa fa-video" id="videoConference" />
      </Link>
    </>
  )

  const callPatientBtn = () => (
    <>
      {props.fromAppointmentDetails &&
        <div className="p-2 d-inline-block ml-4">
          Call Patient:
        </div>
      }
      <Link to="" className="text-primary" onClick={call}>
        <i className="fa fa-phone" id="callPatient" />
        <UncontrolledTooltip placement="top" target="callPatient">Call Patient</UncontrolledTooltip>
      </Link>
    </>
  )

  const lockBtn = () => (
    <Button onClick={lock} className="text-success" disabled={isLocked === LoadingState.loading} color="link">
      {isLocked === LoadingState.loading ?
        <i className="fa fa-spinner" id="lockAppointment" />
        : <i className="fa fa-lock" id="lockAppointment" />}
      <UncontrolledTooltip placement="top" target="lockAppointment">Lock Appointment</UncontrolledTooltip>
    </Button>
  )

  const rescheduleBtn = () => (
    <Link to="" className="text-muted" onClick={reschedule}>
      <i className="fa fa-calendar-alt" id="rescheduleAppointment" />
      <UncontrolledTooltip placement="top" target="rescheduleAppointment">Reschedule Appointment</UncontrolledTooltip>
    </Link>
  )

  const cancelApptBtn = () => (
    <Link to="" className="text-danger" onClick={() => toggleCancelModal()}>
      <i className="fa fa-times" id="cancelAppointment" />
      <UncontrolledTooltip placement="top" target="cancelAppointment">Cancel Appointment</UncontrolledTooltip>
    </Link>
  )

  const textChatBtn = () => (
    <Link to="" className="text-muted"
      onClick={onChatButtonClicked}
    >
      <UncontrolledTooltip placement="top" target="textChat">Chat with{isPhysician() ? " Patient" : " Doctor"}</UncontrolledTooltip>
      <i className="far fa-comments " id="textChat" />
    </Link>
  )

  const textChatBtnWithDot = () => (
    <Link to="" className="text-muted" onClick={onChatButtonClicked} style={{ position: "relative", padding: 2 }}>
      <UncontrolledTooltip placement="top" target="textChat">Chat with{isPhysician() ? " Patient" : " Doctor"}</UncontrolledTooltip>
      <i className="fa fa-circle" id="textChat" style={{ fontSize: 7, color: 'red', top: 4, left: 0, position: "absolute" }} />
      <i className="far fa-comments " id="textChat" />
    </Link>
  )

  const isPhysician = (): boolean => {
    return UserConfig.getUserType() === "physician"
  }

  const rescheduleAppointment = (newTime: Date) => {
    reschedulePatientAppointment(props.appointment.appointmentId, newTime).then(res => {
      props.updateItem && props.updateItem(res.app)
      toastSuccess(res.message)
      toggleRescheduleModal()
    }).catch(err => console.log(err.message))
  }

  const unlockAppt = (reason: IUnlockReason) => {
    unlockAppointment(props.appointment.appointmentId, reason).then(res => {
      if ('message' in res) {
        toastError(res.message)
      } else {
        props.refresh && props.refresh()
        toastSuccess("Appointment Unlocked!")
        // toggleUnlockModal()
      }
    }).catch(err => console.log(err.message))
  }

  const getMeeting = async (zoomAccount: string) => {
    if (props.appointment.status === "Locked") {
      const response = await startAppointment(props.appointment.appointmentId).catch(error => { console.error(error); return { status: error }; })
      if (response.status === "Started") {
        await getZoomMeetingLink({ userId: zoomAccount, appointmentId: props.appointment.appointmentId }).then((response) => {
          url = response.join_url;
        });
        if (props.fromAppointmentDetails) {
          props.refresh && props.refresh()
        }
      }
    } else {
      await getZoomMeetingLink({ userId: zoomAccount, appointmentId: props.appointment.appointmentId }).then((response) => {
        url = response.join_url;
      });
    }
  }

  const onChatButtonClicked = (e: React.MouseEvent) => {
    e.preventDefault();
    props.appointment.patientName && props.appointment.physicianName && props.onChatOpened &&
      props.onChatOpened(props.appointment.appointmentId, isPhysician() ? props.appointment.patientName
        : (props.appointment.physicianName + ", " + props.appointment.physicianTitle))
    props.appointment.newMessage = false
  };

  const status = props.appointment.status
  const newMessage = props.appointment.newMessage //props.appointement.newmessage
  if (isPhysician()) {
    /* Physician Actions*/
    var duration = moment.duration(moment(props.appointment.time).diff(moment(new Date())));
    var minutes = duration.asMinutes();
    if (status === "Locked" || status === "Started") {
      return (
        <div className="actionsStyle">
          <UnlockAppointmentReasonModal isOpen={unlockModal} unlock={unlockAppt} toggleModal={toggleUnlockModal} cancelAppt={cancelByPhysician} />
          {!props.fromAppointmentDetails && unlockBtn()}
          {videoChatBtn()}
          {callPatientBtn()}
          {!props.fromAppointmentDetails && (newMessage ? textChatBtnWithDot() : textChatBtn())}
        </div>
      )
    } else if (status === "Waiting" && minutes <= timeBeforeLock) {
      return (
        <div className="actionsStyle">
          {lockBtn()}
        </div>
      )
    } else if (status === "Completed") {
      return (
        <div className="actionsStyle">
          {callPatientBtn()}
          {!props.fromAppointmentDetails && (newMessage ? textChatBtnWithDot() : textChatBtn())}
        </div>
      )
    } else if (status === "Archived") {
      return (
        <div className="actionsStyle">
          {callPatientBtn()}
        </div>
      )
    } else {
      return <div></div>
    }
  } else {
    /* Patient Actions*/
    if (status === "Rescheduled" || status === "Waiting") {
      return (
        <div className="actionsStyle">
          <RescheduleAppointmentModal modalIsOpen={rescheduleModal} oldTime={props.appointment.time} appointment={props.appointment}
            setNewTime={rescheduleAppointment} toggleModal={toggleRescheduleModal} />
          <AlertMessage isOpen={cancelModal} toggleOpen={toggleCancelModal} message={"Are you sure you want to cancel your appointment?"}
            setResponse={cancelByPatient} options={["Yes, Cancel Appointment", "No, Keep Appointment"]} />
          {rescheduleBtn()}
          {cancelApptBtn()}
        </div>
      )
    } else if (status === "Locked" || status === "Completed" || status === "Started") {
      return (
        <div className="actionsStyle">
          {newMessage ? textChatBtnWithDot() : textChatBtn()}

        </div>
      )
    } else {
      return <div></div>
    }
  }
}
