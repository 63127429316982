import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router';
import {
    CardBody, Button, Container, Input, Label,
    FormGroup, CustomInput, Form, FormFeedback
} from 'reactstrap';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import { navItem, toastError } from '../../App';
import { IBookingProps } from './AppointmentModels';
import { Routes } from '../../config/Routes';
import Asterisk from '../../components/commons/Asterisk';
import { strings } from '../../Constants';
import { getCoPayment, getImage, getUserHasInsurance } from '../../core/service/services';
import InsuranceSectionRedux from '../../components/patientSettings/insurance/InsuranceSectionRedux';
import NotListedInsuranceModal from './insurance/NotListedInsuranceModal';
import Gallery from 'react-photo-gallery';
import { LoadingButton } from '../../components/commons/LoadingButton';
import { NotEligibleModal } from './insurance/NotEligibleModal';
import { useAppDispatch, useAppSelector } from '../../../appredux/hooks';
import {
    copaymentTokenChanged,
    copaymentAmountChanged,
    isCoveredByInsuranceChanged,
    isPrimarySubscriberBooleanChanged,
    haveInsuranceChanged,
    insuranceDetailsChanged,
    showInsuranceDialogChanged,
    insuranceNotListedChanged,
    showGalleryChanged,
    attachmentAdded,
    attachmentsCleared,
    photoTokensChanged,
    primarySubscriberUpdated,
    isInsuranceClickedBooleanUpdated,
    insuranceStepCleared,
    selectInsuranceDetails,
    selectInsuranceTemEntity,
    selectIsPrimarySubscriber,
    selectCopaymentToken,
    selectCopaymentAmount,
    selectIsCoveredByInsurance,
    selectPrimarySubscriber,
    selectIsInsuranceClicked,
    displayedImageAdded,
} from '../../redux/slices/booking/insuranceStepSlice'
import {
    insuranceAttachmentsUpdated,
    insuranceIdUpdated,
    copaymentTokenUpdated,
    copaymentAmountUpdated,
    isCoveredByInsuranceBooleanUpdated,
    insuranceTemEntityUpdated,
    isNotMainSubscriberUpdated,
    primarySubscriberUpdated as primarySubscriberInCreateApptPayloadUpdated,
    previousStepInitiated,
    nextStepInitiated,
    selectTransactionFee,
    selectPaymentAmount,
} from '../../redux/slices/booking/bookingSlice'
import { selectDependent } from '../../redux/slices/booking/choosePatientStepSlice'
import { selectContactInfo } from '../../redux/slices/general/patientInfoSlice';
import { IPhotoToken, ISubscriberObject } from '../../redux/types/interfaces/bookingInterfaces'
import { IDependent, IContact } from "../../redux/types/interfaces/patientInfoInterfaces";
import { errorsDisplayed, selectShowErrors } from '../../redux/slices/booking/errorSlice';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { isDefined, isInputDefined } from '../../core/service/Validators';
import { ContactErrorMessages, PaymentErrorMessages } from '../../core/service/ErrorMessages';
import { areThereErrors } from '../../core/service/ErrorService';
import { RelationshipToDependable } from '../../redux/types/enums/patientInfoEnums';
import { ApiServiceError } from '../../core/service/ServiceCall';

const BookingInsuranceStep = ({
    smallCard
}: IBookingProps) => {
    const insuranceDetails = useAppSelector(selectInsuranceDetails)
    const {
        haveInsurance,
        attachments,
        photoTokens,
        insuranceNotListed,
        showGallery,
        showInsuranceDialog,
        displayedImages
    } = useAppSelector(selectInsuranceTemEntity)
    const copaymentToken: string | undefined = useAppSelector(selectCopaymentToken)
    const copaymentAmount: number | undefined = useAppSelector(selectCopaymentAmount)
    const isCoveredByInsurance: boolean | undefined = useAppSelector(selectIsCoveredByInsurance)
    const isPrimarySubscriber: boolean | undefined = useAppSelector(selectIsPrimarySubscriber)
    const primarySubscriber: ISubscriberObject | undefined = useAppSelector(selectPrimarySubscriber)
    const contactInfo: IContact = useAppSelector(selectContactInfo)
    const dependent: IDependent | undefined = useAppSelector(selectDependent)
    const transactionFee = useAppSelector(selectTransactionFee)
    const paymentAmount: number | undefined = useAppSelector(selectPaymentAmount)
    const isInsuranceClicked: boolean | undefined = useAppSelector(selectIsInsuranceClicked)
    const showErrors = useAppSelector(selectShowErrors);

    const [showNotEligibleModal, setShowNotEligibleModal] = useState<boolean>(false)
    const [showInsuranceNotListedModal, setShowInsuranceNotListedModal] = useState<boolean>(false)
    const [cleanedUpStateAfterEligibility, setCleanedUpStateAfterEligibility] = useState<boolean>(false)
    const [eligibleButtonLoading, setEligibleButtonLoading] = useState<boolean>(false)

    const dispatch = useAppDispatch();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (cleanedUpStateAfterEligibility) {
            updateCreateApptPayload()
        }
    }, [cleanedUpStateAfterEligibility])

    const onSave = (photos: IPhotoToken[]) => {
        dispatch(photoTokensChanged(photos))
        fetchBase64Images(photos)
        setShowInsuranceNotListedModal(false)
    }

    const fetchBase64Images = (photoTokens: IPhotoToken[]) => {
        if (photoTokens) {
            photoTokens.map(async (photo) => {
                try {
                    const res = await getImage(photo.photoToken)
                    dispatch(attachmentAdded(photo.photoToken))
                    dispatch(displayedImageAdded(res.imageBase64))
                } catch (err) { console.log(err) }
            })
            dispatch(showGalleryChanged(true))
            dispatch(insuranceNotListedChanged(true))
        }
    }

    const onGetBackAfterNotEligible = async (yesNotListed: boolean) => {
        if (yesNotListed) {
            setShowInsuranceNotListedModal(true)
        } else {
            dispatch(insuranceStepCleared())
            setCleanedUpStateAfterEligibility(true)
            dispatch(haveInsuranceChanged(false))
        }
    }

    const checkCoPaymentEligibility = async () => {
       setEligibleButtonLoading(true);
       if (insuranceDetails) {
          try {
             const eligibility = await getCoPayment(
                insuranceDetails.id,
                (responseBody) => !responseBody.pending
             );

             dispatch(isCoveredByInsuranceChanged(eligibility.covered));
             setEligibleButtonLoading(false);

             if (eligibility.covered) {
                if (eligibility.copayment) {
                   dispatch(copaymentAmountChanged(copaymentAmount));
                   dispatch(copaymentTokenChanged(copaymentToken));
                }
                updateCreateApptPayload();
             } else setShowNotEligibleModal(true);
          } catch (error) {
             const err = error as ApiServiceError;
             if (err && err.json) {
                toastError(err.json.message);

                let errors = err.json.errors as Array<{
                   field?: string;
                   errorMessage: string;
                }>;

                errors.forEach((jsonObject) => {
                   toastError(jsonObject.errorMessage);
                });
             } else toastError(err.message);
             setEligibleButtonLoading(false);
             setShowNotEligibleModal(true);
          }
       } else setEligibleButtonLoading(false);
    };

    const updateCreateApptPayload = () => {
        if (!haveInsurance) {
            dispatch(nextStepInitiated())
            return
        }
        const insuranceTemEntity = {
            haveInsurance,
            insuranceDetails,
            showInsuranceDialog,
            insuranceNotListed,
            showGallery,
            attachments,
            photoTokens
        }
        dispatch(insuranceTemEntityUpdated(insuranceTemEntity))
        if (insuranceDetails) {
            dispatch(insuranceIdUpdated(insuranceDetails.id))
        }
        if (showGallery && photoTokens) {
            dispatch(insuranceAttachmentsUpdated(photoTokens.map(token => token.photoToken)))
        }
        if (!isPrimarySubscriber) {
            dispatch(isNotMainSubscriberUpdated(!isPrimarySubscriber))
            if (primarySubscriber) {
                dispatch(primarySubscriberInCreateApptPayloadUpdated(primarySubscriber))
            }
        }
        if (copaymentAmount) {
            dispatch(copaymentAmountUpdated(copaymentAmount))
        }
        if (copaymentToken) {
            dispatch(copaymentTokenUpdated(copaymentToken))
        }
        if (isCoveredByInsurance) {
            dispatch(isCoveredByInsuranceBooleanUpdated(isCoveredByInsurance))
        }
        dispatch(nextStepInitiated())
    }

    const getInsuranceOfTheUser = () => {
        if (!insuranceDetails) {
            getUserHasInsurance(dependent?.id)
                .then((result) => {
                    if (Object.entries(result).length !== 0) {
                        dispatch(insuranceDetailsChanged(result))
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }

    const handleIsPrimarySubscriberChanged = () => {
        dispatch(isPrimarySubscriberBooleanChanged(!isPrimarySubscriber))
    }

    const generateImage = (file: string): any => {
        if (file) {
            const imageUrl = 'data:image/jpeg;base64, ' + file;
            return imageUrl;
        }
        return null;
    };

    const renderFlowNav = () => {
        return (
            <div className="flow-nav-tools">
                <Button
                    onClick={() => { dispatch(previousStepInitiated()) }}
                    size="lg"
                    className="control-nav ml-auto"
                    outline
                    color="secondary"
                >
                    Back
                </Button>
                <span className="text-muted mt-2 ml-auto">
                    Step 6 of 7
                </span>
                {returnButtonNextText()}
            </div>
        );
    };
    const onNext = (submitFunction: any) => {
        if (areThereErrors()) {
            dispatch(errorsDisplayed(true))
        }
        else {
            submitFunction()
            dispatch(errorsDisplayed(false))

        }

    }
    const returnButtonNextText = () => {
        if (insuranceDetails && (!attachments || attachments.length === 0)) {
            return (
                <LoadingButton onClick={() => { onNext(checkCoPaymentEligibility) }} color="primary" size="md" className="control-nav mr-auto" isLoading={eligibleButtonLoading}>
                    Check Insurance Eligibility
                </LoadingButton>
            )
        } else {
            return (
                <Button onClick={() => { onNext(updateCreateApptPayload); }} color="primary" size="lg" className="control-nav mr-auto">
                    Next
                </Button>
            )
        }
    }

    const renderTitle = () => {
        let fullName = ''
        if (dependent) {
            fullName = `${dependent.firstname} ${dependent.lastname}`
        } else {
            fullName = `${contactInfo?.firstname} ${contactInfo?.lastname}`
        }
        return `Book an Appointment for ${fullName}`
    }

    const renderInsuranceDialogs = () => {
        if (haveInsurance && showInsuranceDialog) {
            return (
                <>
                    <br />
                    <InsuranceSectionRedux />
                    {renderCheckInsuranceNotListed()}
                    <FormGroup>
                        <div className="custom-checkbox custom-control">
                            <Input
                                id="cardCarrier"
                                type="checkbox"
                                checked={isPrimarySubscriber}
                                onChange={handleIsPrimarySubscriberChanged}
                            />
                            <Label> I am the Primary Subscriber</Label>
                            <br />
                        </div>
                        {renderNotPrimaryCardHolder()}
                    </FormGroup>
                </>
            )
        }
    }

    const renderCheckInsuranceNotListed = () => {
        if (insuranceDetails === undefined) {
            return (
                <>
                    <div className="custom-checkbox custom-control">
                        <Input
                            type="checkbox"
                            id="insrance_not_listed"
                            className="custom-control-input"
                            defaultChecked={false}
                            checked={insuranceNotListed !== undefined && insuranceNotListed === true && attachments !== undefined}
                            onChange={(e) => {
                                if (showGallery) {
                                    dispatch(showGalleryChanged(false))
                                    dispatch(insuranceNotListedChanged(false))
                                    dispatch(attachmentsCleared())
                                    dispatch(photoTokensChanged(undefined))
                                } else {
                                    setShowInsuranceNotListedModal(true)
                                }
                            }}
                        />
                        <Label className="custom-control-label" for="insrance_not_listed">
                            Insurance Not Listed
                        </Label>
                    </div>
                </>
            )
        }
    }

    const renderNotPrimaryCardHolder = () => {
        if (!isPrimarySubscriber) {
            return (
                <>
                    <Label for="firstName">Primary Card Holder's First Name <Asterisk /></Label>
                    <Input
                        type="text"
                        id="firstName"
                        name="firstName"
                        invalid={!isInputDefined(primarySubscriber?.firstName) && showErrors}
                        required
                        defaultValue={primarySubscriber ? primarySubscriber.firstName : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(primarySubscriberUpdated({ ...primarySubscriber, firstName: e.target.value }))
                        }}
                    ></Input>
                    <FormFeedback className='error' hidden={isInputDefined(primarySubscriber?.firstName)}>{ContactErrorMessages.invalidFirstName}</FormFeedback>
                    <br />
                    <Label for="lastName">Primary Card Holder's Last Name <Asterisk /></Label>
                    <Input
                        type="text"
                        id="lastName"
                        name="lastName"
                        invalid={!isInputDefined(primarySubscriber?.lastName) && showErrors}
                        required
                        defaultValue={primarySubscriber ? primarySubscriber.lastName : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(primarySubscriberUpdated({ ...primarySubscriber, lastName: e.target.value }))
                        }}
                    ></Input>
                    <FormFeedback className='error' hidden={isInputDefined(primarySubscriber?.lastName)}>{ContactErrorMessages.invalidLastName}</FormFeedback>
                    <br />
                    <Label for="dateOfBirth">Primary Card Holder's Date Of Birth <Asterisk /></Label>
                    <Input id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        required
                        className="dateOfBirth"
                        invalid={!isInputDefined(primarySubscriber?.dateOfBirth) && showErrors}
                        defaultValue={primarySubscriber ? primarySubscriber.dateOfBirth : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(primarySubscriberUpdated({ ...primarySubscriber, dateOfBirth: e.target.value }))
                        }}
                    />
                    <FormFeedback className='error' hidden={isInputDefined(primarySubscriber?.dateOfBirth)}>{ContactErrorMessages.enterDateofBirth}</FormFeedback>
                    <br />
                    <Label for="relation">Primary Card Holder's Relation <Asterisk /></Label>
                    <Input
                        type="select"
                        defaultValue={primarySubscriber ? primarySubscriber.relation : "none"}
                        required
                        id="relation"
                        name="relation"
                        invalid={!isInputDefined(primarySubscriber?.relation) && showErrors}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            //TO-DO: Change option values to Enum
                            dispatch(primarySubscriberUpdated({ ...primarySubscriber, relation: e.target.value }))
                        }}
                    >
                        <option value="none">Please Select Relation</option>
                        <option key={"Self"} value={"Self"} >
                            Self
                        </option>
                        <option key={"Spouse"} value={"Spouse"} >
                            Spouse
                        </option>
                        <option key={"FamilyMember"} value={"FamilyMember"} >
                            FamilyMember
                        </option>
                        <option key={"NonFamilyMember"} value={"NonFamilyMember"} >
                            NonFamilyMember
                        </option>
                    </Input>
                    <FormFeedback className='error' hidden={isInputDefined(primarySubscriber?.relation)}>{PaymentErrorMessages.selectRelation}</FormFeedback>
                </>
            )
        }
    }
    const renderInsuranceDetails = () =>{
        if (haveInsurance && showGallery && displayedImages){
            return <CardBody>
            {renderAttachments()}
            {insuranceText()}
        </CardBody>
        }
 
    }
    const renderAttachments = () => {
             return (
                    <Container>
                        {photoTokens?.map((file:IPhotoToken,index)=>{
                            if(file.photoName.split('.').pop()=='pdf'){
                                return renderPDF(displayedImages![index])
                            }
                            else{
                                return renderImage(displayedImages![index])
                            }
                        })}
                    </Container>
            );
            
    }

    
    const renderPDF = (pdfStringBase64:string) =>{
        return  <embed width={400} height={400} src={`data:application/pdf;base64,${pdfStringBase64}`} />
         }
    const renderImage = (imageBase64:string) =>{
        const image = [{
            src:generateImage(imageBase64),
            width:1,
            height:1
        }]
        return <Gallery photos={image} columns={8}  />
    }
    const insuranceText = ()=>{
        if (paymentAmount && transactionFee){
            const num = paymentAmount + transactionFee;
            return <div>
                 Your insurance card is successfully uploaded. We will contact your insurance company to get co-payment info. For now, the full service fees
                 <b>{num}$ </b>
                 will be authorized with online payment, and in 3 - 4 weeks, you will get a refund depending on your insurance company feedback
                 </div>
        }
    }

    return (
        <>
            <NotListedInsuranceModal
                isOpen={showInsuranceNotListedModal}
                toggle={() => { setShowInsuranceNotListedModal(!showInsuranceNotListedModal) }}
                onSave={onSave}
            />
            <NotEligibleModal
                isOpen={showNotEligibleModal}
                toggleModal={() => { setShowNotEligibleModal(!showNotEligibleModal) }}
                onSave={onGetBackAfterNotEligible}
            />
            <Form>
                <SideCardFrame flowNav={renderFlowNav()} navItem={navItem} bodyClassName="tc-bg" smallCard={smallCard}>
                    <CardBody>
                        <div className="d-flex px-2">
                            <h5 className="mb-0">
                                {renderTitle()}
                            </h5>
                            <Link
                                to={Routes.homePatient}
                                className="btn btn-link btn-sm ml-auto"
                            >
                                Cancel
                            </Link>
                        </div>
                        <hr className="mt-2 mb-4"></hr>
                        <Container>
                            <FormGroup>
                                <Label for="HaveInsurance">{strings.patientBooking.insuranceQuestionTitle}</Label>
                                <div id="HaveInsurance">
                                    <CustomInput
                                        type="radio"
                                        id="yesInsurance"
                                        name="yesInsurance"
                                        label="Insurance"
                                        checked={isInsuranceClicked}
                                        disabled={dependent?.relationshipToDependable === RelationshipToDependable.NonFamilyMember}
                                        onChange={(e) => {
                                            dispatch(haveInsuranceChanged(true));
                                            dispatch(showInsuranceDialogChanged(true))
                                            dispatch(isInsuranceClickedBooleanUpdated(true))
                                            getInsuranceOfTheUser()
                                        }}
                                    />
                                    <CustomInput
                                        type="radio"
                                        id="NoInsurance"
                                        name="NoInsurance"
                                        label="Credit Card"
                                        checked={isInsuranceClicked === false}
                                        onChange={(e) => {
                                            dispatch(insuranceStepCleared())
                                            dispatch(isInsuranceClickedBooleanUpdated(false))
                                        }}
                                    />
                                </div>
                                <CustomErrorMessage invalid={!isDefined(isInsuranceClicked)} showValidation={showErrors} errorMessage={PaymentErrorMessages.selectPaymentType} />
                                {renderInsuranceDialogs()}
                                {renderInsuranceDetails()}
                            </FormGroup>
                        </Container>
                    </CardBody>
                </SideCardFrame>
            </Form>
        </>
    )
}

export default BookingInsuranceStep